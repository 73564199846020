import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import TimestampsListView from "../components/TimestampsListView"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"
import SideBarView from "../components/SideBarView"
import { Grid } from "@mui/material"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

const TimestampsList = () => {

  return (
    <Container>
      <CustomHeader title={`Unix / Epoch Timestamps List`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <p>This page shows all <Link target={`blank`}
                                       to={`https://en.wikipedia.org/wiki/Timestamp`}>timestamp</Link> conversions like
            a
            date into epoch timestamp for all recent and upcoming timestamps.
            All online timestamp conversions on this page are in seconds, If you want to see a Millisecond timestamp,
            you can add 000 trailing zeros at the end and also all the Unix to formatted date conversions are in <Link
              target={`blank`} to={`/timezones-converter`}>timezone</Link> UTC.</p>
          <TimestampsListView/>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default TimestampsList

export const Head = () => {
  const siteMetadata = {
    description: "List of timestamp and formatted date time of recent and upcoming times will be displayed.",
    description2: "",
    keywordsContent: "timestamp, unix, epoch, hours, days, months, years, decade, utc"
  }
  return (
    <Seo
      title="Unix / Epoch Timestamps List"
      canonicalUrl={"https://www.epochconverter.io/timestamps-list/"}
      siteMetadata={siteMetadata}
    />
  )
}
