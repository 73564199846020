import React from "react"
import { Row, Col } from "antd"
import dayjs from "dayjs"
import { DataGrid } from "@mui/x-data-grid"
import { Box } from "@mui/material"
import localizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(localizedFormat)

var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


const CustomClockCard = ({ title }) => {
  let timestampValue = dayjs().valueOf()

  const getTimestampValue = (timestamp, operation, value, valueType) => {
    if (operation === "subtract") {
      return dayjs(timestampValue).subtract(value, valueType).valueOf()
    } else if (operation === "add") {
      return dayjs(timestampValue).add(value, valueType).valueOf()
    }
  }

  const columns = [
    {
      headerName: "Time",
      field: "time",
      key: "time",
      sorter: (a, b) => a.timestamp - b.timestamp,
      fixed: "left",
      flex: 1
    },
    {
      headerName: "Unix / Epoch Timestamp",
      field: "timestamp",
      key: "timestamp",
      sorter: (a, b) => a.timestamp - b.timestamp,
      flex: 1.5
    },
    {
      headerName: "Formatted Date and time",
      field: "fDateTime",
      key: "fDateTime",
      sorter: (a, b) => a.timestamp - b.timestamp,
      flex: 1.5
    }
  ]

  const recentDates = [
    { time: "NOW", timestamp: timestampValue },
    { time: "1 hour ago", timestamp: getTimestampValue(timestampValue, "subtract", 1, "hour") },
    { time: "2 hour ago", timestamp: getTimestampValue(timestampValue, "subtract", 2, "hour") },
    { time: "3 hour ago", timestamp: getTimestampValue(timestampValue, "subtract", 3, "hour") },
    { time: "6 hour ago", timestamp: getTimestampValue(timestampValue, "subtract", 6, "hour") },
    { time: "12 hour ago", timestamp: getTimestampValue(timestampValue, "subtract", 12, "hour") },
    { time: "1 day ago (yesterday)", timestamp: getTimestampValue(timestampValue, "subtract", 1, "day") },
    { time: "2 days ago", timestamp: getTimestampValue(timestampValue, "subtract", 2, "days") },
    { time: "3 days ago", timestamp: getTimestampValue(timestampValue, "subtract", 3, "days") },
    { time: "1 week ago (last week)", timestamp: getTimestampValue(timestampValue, "subtract", 1, "week") },
    { time: "2 weeks ago", timestamp: getTimestampValue(timestampValue, "subtract", 2, "weeks") },
    { time: "3 weeks ago", timestamp: getTimestampValue(timestampValue, "subtract", 3, "weeks") },
    { time: "4 weeks ago (28 days)", timestamp: getTimestampValue(timestampValue, "subtract", 4, "weeks") },
    { time: "1 month ago (same day last month)", timestamp: getTimestampValue(timestampValue, "subtract", 1, "month") },
    { time: "2 months ago", timestamp: getTimestampValue(timestampValue, "subtract", 2, "months") },
    { time: "3 months ago", timestamp: getTimestampValue(timestampValue, "subtract", 3, "months") },
    { time: "6 months ago", timestamp: getTimestampValue(timestampValue, "subtract", 6, "months") },
    { time: "9 months ago", timestamp: getTimestampValue(timestampValue, "subtract", 9, "months") },
    { time: "1 year ago (last year)", timestamp: getTimestampValue(timestampValue, "subtract", 1, "year") },
    { time: "2 years ago", timestamp: getTimestampValue(timestampValue, "subtract", 2, "years") },
    { time: "3 years ago", timestamp: getTimestampValue(timestampValue, "subtract", 3, "years") },
    { time: "5 years ago", timestamp: getTimestampValue(timestampValue, "subtract", 5, "years") },
    { time: "10 years ago", timestamp: getTimestampValue(timestampValue, "subtract", 10, "years") }
  ]

  recentDates.forEach(item => {
    item.fDateTime = dayjs(item.timestamp).format("LLLL z")
  })

  const upcomingDates = [
    { time: "NOW", timestamp: timestampValue },
    { time: "In 1 hour", timestamp: getTimestampValue(timestampValue, "add", 1, "hour") },
    { time: "In 2 hour", timestamp: getTimestampValue(timestampValue, "add", 2, "hour") },
    { time: "In 3 hour", timestamp: getTimestampValue(timestampValue, "add", 3, "hour") },
    { time: "In 6 hour", timestamp: getTimestampValue(timestampValue, "add", 6, "hour") },
    { time: "In 12 hour", timestamp: getTimestampValue(timestampValue, "add", 12, "hour") },
    { time: "In 1 day (tomorrow)", timestamp: getTimestampValue(timestampValue, "add", 1, "day") },
    { time: "In 2 days", timestamp: getTimestampValue(timestampValue, "add", 2, "days") },
    { time: "In 3 days", timestamp: getTimestampValue(timestampValue, "add", 3, "days") },
    { time: "In 1 week (next week)", timestamp: getTimestampValue(timestampValue, "add", 1, "week") },
    { time: "In 2 weeks", timestamp: getTimestampValue(timestampValue, "add", 2, "weeks") },
    { time: "In 3 weeks", timestamp: getTimestampValue(timestampValue, "add", 3, "weeks") },
    { time: "In 4 weeks (28 days)", timestamp: getTimestampValue(timestampValue, "add", 4, "weeks") },
    { time: "In 1 month (same day next month)", timestamp: getTimestampValue(timestampValue, "add", 1, "month") },
    { time: "In 2 months", timestamp: getTimestampValue(timestampValue, "add", 2, "months") },
    { time: "In 3 months", timestamp: getTimestampValue(timestampValue, "add", 3, "months") },
    { time: "In 6 months", timestamp: getTimestampValue(timestampValue, "add", 6, "months") },
    { time: "In 9 months", timestamp: getTimestampValue(timestampValue, "add", 9, "months") },
    { time: "In 1 year (next year)", timestamp: getTimestampValue(timestampValue, "add", 1, "year") },
    { time: "In 2 years", timestamp: getTimestampValue(timestampValue, "add", 2, "years") },
    { time: "In 3 years", timestamp: getTimestampValue(timestampValue, "add", 3, "years") },
    { time: "In 5 years", timestamp: getTimestampValue(timestampValue, "add", 5, "years") },
    { time: "In 10 years", timestamp: getTimestampValue(timestampValue, "add", 10, "years") }
  ]

  upcomingDates.forEach(item => {
    item.fDateTime = dayjs(item.timestamp).format("LLLL z")
  })

  return (
    <div>
      <Row style={{ marginTop: "50px" }}>
        <Col>
          <h2
            style={{
              color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Recent Dates
          </h2>
          <p>The start timestamp in the below table is the time that this is loaded. The below table shows a few
            timestamps data in the range from now to the previous decade.</p>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid getRowId={row => row.time} rows={recentDates} columns={columns} rowKey={"timezone"}
                      bordered size="small" pagination={false} sticky/>
          </Box>
        </Col>
      </Row>
      <Row style={{ marginTop: "50px" }}>
        <Col>
          <h2
            style={{
              color: "#4d3267",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            Upcoming Dates
          </h2>
          <p>The timestamp in the below table is based on the loading time of this page. The below table shows a few
            timestamps data in the range from now to the next decade.</p>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid getRowId={row => row.time} rows={upcomingDates} columns={columns} rowKey={"timezone"}
                      bordered size="small" pagination={false} sticky/>
          </Box>
        </Col>
      </Row>
    </div>
  )
}

export default CustomClockCard
